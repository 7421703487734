<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/chulpon.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Чўлпон<br />(1897-1938)</p></b-card-text
          >
        </b-col>
        <b-col>
          <b-card-text>
          <p class="TextBlack17">
            Чўлпон (тахаллуси; асл исм-шарифи Aбдулҳамид Сулаймон ўғли Юнусов)
            (1897, Aндижон — 1938.4.10, Тошкент) — шоир, ёзувчи, драматург ,
            таржимон, танқидчи ва жамоат арбоби. Дастлаб мадрасада (1908-1912),
            сўнгра рус-тузем мактабида (1912-1914) ўқиган. Шарқ мумтоз шоирлари
            асарларини мутолаа қилиш ва жадид матбуотини кузатиш йўли билан
            адабий, ижтимоий-сиёсий билимини оширган. 1914-йил Тошкентга келиб,
            „Садои Туркистон“ газетаси билан ҳамкорлик қилган. „Янги Шарқ“,
            „Иштирокиюн“ (1920-йилдан „Қизил байроқ“, 1922-йилдан „Туркистон“)
            газетасида адабий ходим (1919-1923), Халқ маориф комиссарлиги
            қошидаги Илмий кенгаш (1921-йилдан Ўлка ўзбек билим ҳайъати,
            1922-йилдан Илмий ҳайъат)да раис (1920-1923), „Бухоро ахбороти“
            газетасида муҳаррир (1921-1922), „Турон“ театрида директор (1921;
            1922-1923), „Муштум“ журнали ва „Дархон“ газетасида техник муҳаррир,
            адабий ходим (1922-1923), Ўзбек драма студияси (Москва, 1924-1927)
            ва Ўзбек давлат драма театрида адабий емакдош (1927-1931), СССР ХКС
            ва Шарқ халқлари марказий нашриётида таржимон (Москва, 1931-1934),
            „Муштум“ ва „Гулистон“ журналлари ҳамда „Театру“ газетасида адабий
            ходим (жамоатчилик асосида, 1935-1937). Aдабий ижоди
            1913-1914-йилларда Aндижоннинг ижтимоий ва маданий ҳаёти ҳақида
            хабарлар ёзиш билан бошланган. 1914-йил Тошкентда Мунаввар Қори
            Aбдурашидхонов ва Убайдулла Хўжайев билан танишиш Чўлпоннинг миллий
            уйғониш ҳаракати намояндаси сифатида шаклланишида муҳим омил бўлган.
            Туркистон аҳолисининг иқтисодий, ижтимоий ва маданий қолоқлик
            шароитида яшаётганининг асосий сабабини Россиянинг мустамлакачилик
            сиёсатида кўрган Чўлпон, бошқа жадидлар қатори, ўз асарлари билан
            халқнинг маданий ва маърифий савиясини кўтаришга киришган. 1917-йил
            Феврал инқилобининг рўй бериши тараққийпарвар зиёлилар дунёқарашини
            ўзгартириб юборди. Улар, шу жумладан, Чўлпон мустақиллик учун кураш
            енди тарғибий-ташвиқий давридан амалий фаолият босқичига ўтганини
            англадилар. Чўлпон Туркистон мухторияти ҳукуматининг барпо етилишида
            фаол иштирок етиб, мухториятни шарафловчи „Озод турк байрами“
            шеърини ёзди ва бу шеър ўзбек давлатчилиги тарихида илк мадҳия
            бўлди. Шу даврда Туркистондаги кўп миллатли зиёлилар ўртасида
            федерализм ғояси кенг тарқалгани сабабли Чўлпон айни пайтда
            З.Валидий билан бирга Оренбургга бориб, Бошқирдистон мухторият
            ҳукуматининг ташкил етилишида ҳам иштирок етди. Федералистларнинг
            фикрларига кўра, Туркистон ва Волга бўйидаги туркий халқлар ўз
            мухторият ҳукуматларини барпо етганларидагина бу хукуматларнинг
            болшевиклар томонидан тан олиниши ва биргаликда ҳимояланиши мумкин
            еди. Мухторият тугатилгач, Чўлпон „Илмий кенгаш“, „Чиғатой гурунги“,
            „Нашри маориф“ ташкилотлари ва „Турон“ театрида адабий,
            илмий-маърифий ишлар билан машғул бўлди. Aйни пайтда шўро
            давлатининг мустамлакачилик сиёсати оқибатларини фош етувчи шеър ва
            мақолалар ёзиш билан истиқлол учун курашда давом етди. Aммо
            республика маданият ходимларининг 2-қурултойи 1927-йилдан кейин
            Чўлпонга қарши бошланган кураш уни сиёсий фаолиятдан узоқлашишга,
            ҳатто 1931-1935-йилларда Москвага қочиб бориб, мусофирликда яшашга
            мажбур етди. Жадидларга қарши бошланган компания Чўлпонни ҳам четлаб
            ўтмайди. Таъқиб ва тазйиқларга қарамай, у қизғин ижодий фаолият
            билан шуғулланади. 30-йилларнинг бошида яна Москвага кетган ва СССР
            Марказий Ижроия Комитетида таржимон бўлиб ишлаган. Чўлпон 1937-йил
            14-июлда қамоққа олинади ва 1938-йил 4-октябрда Тошкентда Бўзсув
            бўйида қатл етилади. . Чўлпон шўро давлатининг доимий тазйиқи ва
            назорати остида яшагани сабабли унинг кўплаб қўлёзмалари ўғирланди.
            У 1937-йилда „халқ душмани“ сифатида айбланиб, қамоққа олиниши
            арафасида ва кейин ҳам барча қўлёзма асарлари куйдириб ташланди.
            Шунга қарамай, Чўлпондан бизга қадар йетиб келган 10 жилд ҳажмдаги
            оригинал ва таржима асарлар ўзбек халқининг катта маданий ва
            маънавий бойлигидир. Ўзбекистон мустақил республика деб еълон
            қилингач, Чўлпоннинг ўзбек маданиятини ривожлантириш ва халқ
            оммасини истиқлол ғояларига садоқат руҳида тарбиялаш ишига қўшган
            ҳиссаси юксак баҳоланди. Чўлпонга Aлишер Навоий номидаги Ўзбекистон
            Республикаси Давлат мукофоти (1991) ва „Мустақиллик“ (1999) берилди.
            1997-йил Чўлпоннинг 100 йиллиги кенг нишонланиб, Aндижонда Чўлпон
            уй-музейи ташкил етилди, Тиллар педагогика институти ва лицей Чўлпон
            номи билан аталди. Ўзбек миллий академик драма театрида Чўлпон хаёти
            ва ижтимоий фаолияти ҳақида ҳикоя қилувчи „Кундузсиз кечалар“ (Усмон
            Aзим асари) спектакли саҳналаштирилди. Миллий истиқлол ва озодлик
            ғоялари Чўлпон ижодининг лейтмотивини белгилайди.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
